var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ground-component" }, [
    _vm.pageType == 1
      ? _c("div", [
          _c("div", { staticClass: "detail-name" }, [_vm._v("申请时间")]),
          _c("div", { staticClass: "detail-content" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("dateFormat")(
                    _vm.dataset.creationDate,
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ) +
                "\n    "
            ),
          ]),
          _c("div", { staticClass: "detail-name" }, [_vm._v("申请人")]),
          _c("div", { staticClass: "detail-content" }, [
            _vm._v(_vm._s(_vm.dataset.ordPersonName)),
          ]),
          _c("div", { staticClass: "detail-name" }, [_vm._v("订单编号")]),
          _c("div", { staticClass: "detail-content" }, [
            _vm._v(_vm._s(_vm.dataset.orderCode)),
          ]),
          _c("div", { staticClass: "detail-name" }, [_vm._v("手机号码")]),
          _c("div", { staticClass: "detail-content" }, [
            _vm._v(_vm._s(_vm.dataset.ordPersonPhone)),
          ]),
          _c("div", { staticClass: "detail-name" }, [_vm._v("申请单位")]),
          _c("div", { staticClass: "detail-content" }, [
            _vm._v(_vm._s(_vm.dataset.enterpriseDescName)),
          ]),
          _vm.dataset.groundDrivingVO
            ? _c("div", [
                _c("div", { staticClass: "detail-name" }, [
                  _vm._v("车辆停放位置"),
                ]),
                _c("div", { staticClass: "detail-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.dataset.groundDrivingVO.vehiclePosition) +
                      "\n      "
                  ),
                ]),
                _c("div", { staticClass: "detail-name" }, [_vm._v("车辆入口")]),
                _c("div", { staticClass: "detail-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.dataset.groundDrivingVO.vehicleEntrance) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "detail-name" }, [_vm._v("工单状态")]),
          _c("div", { staticClass: "detail-content" }, [
            _vm._v(_vm._s(_vm.dataset.orderStatusStr)),
          ]),
          _c("div", { staticClass: "detail-name" }, [_vm._v("订单状态")]),
          _c("div", { staticClass: "detail-content" }, [
            _vm._v(_vm._s(_vm.dataset.customerOrderStatusStr)),
          ]),
          _vm.dataset.vehicleVOList.length > 0
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "detail-name" }, [
                    _vm._v("搬运材料信息"),
                  ]),
                  _vm._l(_vm.dataset.vehicleVOList, function (it, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "detail-car" },
                      [
                        _c("span", [
                          _vm._v(" 货长（m）:" + _vm._s(it.goodsLength) + " "),
                        ]),
                        _c("span", [
                          _vm._v(" 货宽（m）:" + _vm._s(it.goodsWidth) + " "),
                        ]),
                        _c("span", [
                          _vm._v(" 货高（m）:" + _vm._s(it.goodsHeight) + " "),
                        ]),
                        _c("span", [
                          _vm._v(" 货重（kg）:" + _vm._s(it.goodsWeight) + " "),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.dataset.passCard
            ? _c("div", [
                _c("div", { staticClass: "detail-name" }, [
                  _vm._v("通行证上传人"),
                ]),
                _c("div", { staticClass: "detail-content" }, [
                  _vm._v(_vm._s(_vm.dataset.upPassCardBy)),
                ]),
                _c("div", { staticClass: "detail-name" }, [
                  _vm._v("通行证上传时间"),
                ]),
                _c("div", { staticClass: "detail-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("dateFormat")(
                          _vm.dataset.upPassCardTime,
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      ) +
                      "\n      "
                  ),
                ]),
                _c("div", { staticClass: "detail-name" }, [_vm._v("通行证")]),
                _c(
                  "div",
                  { staticClass: "detail-imgList" },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "64px",
                        height: "64px",
                        "border-radius": "6px",
                      },
                      attrs: {
                        src: _vm.imgPath + _vm.dataset.passCard,
                        "preview-src-list": _vm.passPortList,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.dataset.reasonList && _vm.dataset.reasonList.length > 0
            ? _c("div", [
                _c("div", { staticClass: "detail-name" }, [_vm._v("驳回记录")]),
                _c(
                  "div",
                  { staticClass: "detail-reason" },
                  _vm._l(_vm.dataset.reasonList, function (it, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "reason-border" },
                      [
                        _c("div", { staticClass: "reason-title reason-list" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.dataset.reasonList.length - index) +
                              "：" +
                              _vm._s(it.explainCard) +
                              "\n          "
                          ),
                        ]),
                        _c("div", { staticClass: "reason-list" }, [
                          _vm._v("操作人：" + _vm._s(it.userName)),
                        ]),
                        _c("div", { staticClass: "reason-list" }, [
                          _vm._v(
                            "\n            驳回时间：" +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  it.creationDate,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ])
      : _vm.pageType == 2
      ? _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.uploadLoading,
                  expression: "uploadLoading",
                },
              ],
              staticClass: "upload-container",
            },
            [
              !_vm.imageUrl
                ? _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.uploadPath,
                        "with-credentials": true,
                        "show-file-list": false,
                        "on-success": _vm.handleAvatarSuccess,
                        "before-upload": _vm.beforeAvatarUpload,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.imageUrl
                ? _c("el-image", {
                    staticClass: "avatar",
                    attrs: {
                      src: _vm.imageUrl,
                      "preview-src-list": _vm.upLoadPreview,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.fileId,
                      expression: "fileId",
                    },
                  ],
                  staticClass: "delete-icon",
                  on: { click: _vm.deleteHandler },
                },
                [_c("i", { staticClass: "el-icon-delete" })]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page-button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.upLoadImg()
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }